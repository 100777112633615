import React from 'react'
import {LuArrowBigRightDash} from 'react-icons/lu'

const ticket = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div>
            <h2 className='h-[10%] text-3xl font-bold'>Subscribe to get your ticket!</h2>
        </div>
        <div className='h-auto bg-gray-500 w-[40%] cursor-pointer py-4 flex flex-col gap-4 rounded-md border-nav text-white border-2'>
                    <h2 className='px-[10px] text-[25px]'>Choose your plan</h2>
                    <div className='flex gap-2 flex-col'>
                        <div className='flex items-center px-[10px] text-[20px] gap2'>
                            <h2><LuArrowBigRightDash/></h2>
                            <h2>Students</h2>
                        </div>
                        <div className='flex items-center px-[10px] text-[20px] gap2'>
                            <h2><LuArrowBigRightDash/></h2>
                            <h2>Serior Citizen</h2>
                        </div>
                        <div className='flex items-center px-[10px] text-[20px] gap2'>
                            <h2><LuArrowBigRightDash/></h2>
                            <h2>Disabled Person</h2>
                        </div>
                    </div>
        </div>
    </div>
  )
}

export default ticket