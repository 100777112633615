import React, { useState , useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineFolderView } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import axios from 'axios';


const AdminUsers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:3031/commuter");
      setData(response.data);
      console.log(response.data)
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOption2, setSelectedOption2] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
  };

  return (
    <div className="h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4">
      <div className="h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer">
        <div className="border-b-[1px] py-1 px-2 flex justify-between items-center">
          <h2>Users Info:</h2>
          <button className="text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-green-500 border-green-500">
            <Link to={"./add-new-routes"}>Add Users</Link>
            <IoMdAdd color="white" />
          </button>
        </div>
        <div className="flex justify-between px-2 border-b-[1px] py-1">
          <h2>SN</h2>
          <h2>Name</h2>
          <h2>Address</h2>
          <h2>Email</h2>
          <h2>Phone No.</h2>
          <h2>Role</h2>
          <h2>Take action</h2>
        </div>
        <div className="flex flex-col">
                {data.map( (d,i)=>(
                    <div key={i} className="flex justify-between px-2 border-b-[1px] py-2">
                        <h2>{d.id}</h2>
                        <h2>{d.name}</h2>
                        <h2>{d.address}</h2>
                        <h2>{d.email}</h2>
                        <h2>{d.phoneNumber}</h2>
                        <div className="text-black">
                        <select
                            value={selectedOption2}
                            onChange={handleOptionChange2}
                            className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                        >
                            <option value="">Select an option</option>
                            <option value="option1">Commuter</option>
                            <option value="option2">BMC</option>
                        </select>
                        </div>
                        <button className="text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-bodyTheme border-bodyTheme">
                        <Link to={"./admin-view-info"}>View</Link>
                        <AiOutlineFolderView color="white" size={"20px"} />
                        </button>
                  </div>
                ))}
                {/* {data.map((item) => (
          <li key={item.id}>{item.name}</li>
        ))} */}
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
