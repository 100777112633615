import React from 'react'
import {GrStatusWarning} from 'react-icons/gr'


const mainDashboard = () => {
  return (
    <div className='w-[80%] h-[92vh] bg-bodyTheme flex flex-col gap-4 items-center justify-center py-4'>
        <h2 className='h-[10%] text-3xl font-bold cursor-pointer'>Welcome John!</h2>
        <div className='h-[90%] w-full  flex flex-col justify-center items-center gap-8'>
            <div className='flex gap-3 items-center justify-center flex-col'>
                <GrStatusWarning size={'30px'}/>
                <h2>
                   Add details to your profile to get verified from bus owner.
                </h2>
            </div>
        </div>
    </div>
  )
}

export default mainDashboard