import React from 'react'

const ticket = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div>
            <h2 className='font-bold'>Step 2 of 4</h2>
        </div>
        <div>
            <h2 className='px-[10px] text-[25px]  font-semibold'>Choose the right package for you!</h2>
        </div>
        <div className='flex flex-col gap-6 items-center'>
            <div className='flex flex-col border-2 border-nav px-2 py-2 gap-4 w-[140%]'>
               <h2 className=' text-[20px] font-semibold'>Sole Basic Package Fare</h2>
               <div className='flex justify-between'>
                    <div>
                        <h2>One day</h2>
                        <h2>Rs.____</h2>
                    </div>
                    <div>
                        <h2>Weekly</h2>
                        <h2>Rs.____</h2>
                    </div>
                    <div>
                        <h2>Monthly</h2>
                        <h2>Rs.____</h2>
                    </div>
               </div>
            </div>
            <div className='flex flex-col border-2 border-nav px-2 py-2 gap-4 w-[140%]'>
                <div>
                     <h2 className='text-[20px] font-semibold'>Sole Discounted Package Fare</h2>
                     {/* <h2 className='text-[20px] font-semibold'>Only for Students ,Senior aged & disabled</h2> */}
                </div>
               <div className='flex justify-between'>
                    <div>
                        <h2>One day</h2>
                        <h2>Rs.____</h2>
                    </div>
                    <div>
                        <h2>Weekly</h2>
                        <h2>Rs.____</h2>
                    </div>
                    <div>
                        <h2>Monthly</h2>
                        <h2>Rs.____</h2>
                    </div>
               </div>
            </div>
            <div>
                    <button className=' text-center px-[2rem] border-nav border-2'>Next</button>
            </div>
        </div>
        
    </div>
  )
}

export default ticket