import React from 'react'

const ticket = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div>
            <h2 className='font-bold'>Step 3 of 4</h2>
        </div>
        <div>
            <h2 className='px-[10px] text-[25px]  font-semibold'>Upload the required information/documentation</h2>
        </div>
        <div className='flex flex-col gap-6 items-center'>
            <div>
                <input type="number" 
                className="rounded px-2 py-3 h-8 text-[12px] outline-none"
                placeholder='Enter your age!'/>
            </div>
            <div>
                <input type="text" 
                className="rounded px-2 py-3 h-8 text-[12px] outline-none"
                placeholder='Upload photo id!' />
                
                <h2>*for students only!</h2>
            </div>
            <div>
                <h2>Note:- Please upload your authentic ID card.</h2>
            </div>
            <div>
                    <button className=' text-center px-[2rem] border-nav border-2'>Next</button>
            </div>
            
        </div>
        
    </div>
  )
}

export default ticket