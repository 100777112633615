import React , {useState}from 'react'

const Staff = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    };
    const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
    };

  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div className='h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer'>
                    <div className='border-b-[1px] py-1 px-2'>
                        <h2>New Staff Details:</h2>
                    </div>
                    <div className='flex justify-between px-2 border-b-[1px] py-1'>
                        <h2>SN</h2>
                        <h2>Name</h2>
                        <h2>Address</h2>
                        <h2>Phone No.</h2>
                        <h2>Role</h2>
                        <h2>License No.</h2>
                        <h2>License Doc.</h2>
                        <h2>Take action</h2>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex justify-between px-2 border-b-[1px] py-2'>
                            <h2>1</h2>
                            <h2>Dagan Boss</h2>
                            <h2>Illam</h2>
                            <h2>9812345678</h2>
                            <div className='text-black'>
                                <select 
                                value={selectedOption2} 
                                onChange={handleOptionChange2}
                                className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                                >
                                    <option value="">Select an option</option>
                                    <option value="option1">Driver</option>
                                    <option value="option2">Conductor</option>
                                </select>
                                {/* {selectedOption && <p>Selected option: {selectedOption}</p>} */}
                            </div>
                            <h2>123456</h2>
                            <h2>License Doc.</h2>
                            <div className='text-black'>
                                <select 
                                value={selectedOption} 
                                onChange={handleOptionChange}
                                className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                                >
                                    <option value="">Select an option</option>
                                    <option value="option1">Accept</option>
                                    <option value="option2">Reject</option>
                                    <option value="option3">Remove</option>
                                </select>
                                {/* {selectedOption && <p>Selected option: {selectedOption}</p>} */}
                            </div>
                        </div>
                        <div className='flex justify-between px-2 border-b-[1px] py-2'>
                            <h2>2</h2>
                            <h2>Dagan Boss</h2>
                            <h2>Illam</h2>
                            <h2>9812345678</h2>
                            <div className='text-black'>
                                <select 
                                value={selectedOption2} 
                                onChange={handleOptionChange2}
                                className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                                >
                                    <option value="">Select an option</option>
                                    <option value="option1">Driver</option>
                                    <option value="option2">Conductor</option>
                                </select>
                                {/* {selectedOption && <p>Selected option: {selectedOption}</p>} */}
                            </div>
                            <h2>123456</h2>
                            <h2>License Doc.</h2>
                            <div className='text-black'>
                                <select 
                                value={selectedOption} 
                                onChange={handleOptionChange}
                                className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                                >
                                    <option value="">Select an option</option>
                                    <option value="option1">Accept</option>
                                    <option value="option2">Reject</option>
                                    <option value="option3">Remove</option>
                                </select>
                                {/* {selectedOption && <p>Selected option: {selectedOption}</p>} */}
                            </div>
                        </div>
                    </div>
        </div>
        
        
    </div>
  )
}

export default Staff;