import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineFolderView, AiOutlineSearch } from 'react-icons/ai';

const BusList = () => {
  const [busData, setBusData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [busesPerPage] = useState(5);

  useEffect(() => {
    fetch('http://localhost:3031/bus_lists')
      .then((response) => response.json())
      .then((data) => setBusData(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const indexOfLastBus = currentPage * busesPerPage;
  const indexOfFirstBus = indexOfLastBus - busesPerPage;

  const filteredBuses = busData.filter((bus) =>
    bus.busName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentBuses = filteredBuses.slice(indexOfFirstBus, indexOfLastBus);
  const totalPages = Math.ceil(filteredBuses.length / busesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
      <div className='min-h-[50vh] h-auto w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer'>
        <div className='border-b-[1px] py-1 px-2 flex justify-between items-center'>
          <h2>Bus Info:</h2>
          <div className='relative'>
            <input
              type='search'
              className='rounded pl-8 pr-2 w-[8rem] py-2 text-black h-8 text-[12px] outline-none'
              placeholder='Search'
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className='absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500'>
              <AiOutlineSearch size={18} />
            </div>
          </div>
        </div>
        <div className='flex justify-between px-2 border-b-[1px] py-1'>
          <h2>SN</h2>
          <h2>Bus Name</h2>
          <h2>Bus No.</h2>
          <h2>Capacity</h2>
          <h2>IsActive</h2>
          <h2>Take action</h2>
        </div>
        <div className='flex flex-col'>
          {currentBuses.map((bus, index) => (
            <div className='flex justify-between px-2 border-b-[1px] py-2' key={index}>
              <h2>{index + 1}</h2>
              <h2>{bus.busName}</h2>
              <h2>{bus.busNo}</h2>
              <h2>{bus.capacity}</h2>
              <h2>{bus.selectedOption}</h2>
              <button className='text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-bodyTheme border-bodyTheme'>
                <Link to='./bus-view-info'>View</Link>
                <AiOutlineFolderView color='white' size={'20px'} />
              </button>
            </div>
          ))}
        </div>
        
        <div className='flex justify-center py-2'>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`text-white border-[1px] rounded-sm items-center flex text-center justify-center px-2 mx-1 focus:outline-none ${
                currentPage === index + 1 ? 'bg-bodyTheme border-bodyTheme' : ''
              }`}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusList;


















// import React , {useState}from 'react'
// import {Link} from 'react-router-dom'
// import { AiOutlineFolderView } from 'react-icons/ai';



// const BusList = () => {

//     const [selectedOption, setSelectedOption] = useState('');
//     const [selectedOption2, setSelectedOption2] = useState('');

//     const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//     };
//     const handleOptionChange2 = (event) => {
//     setSelectedOption2(event.target.value);
//     };

//   return (
//     <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
//         <div className='h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer'>
//                     <div className='border-b-[1px] py-1 px-2 flex justify-between items-center'>
//                         <h2>Users Info:</h2>
//                         <button className='text-white gap-2 items-center flex text-center justify-center px-2'>
//                             <h2>Search:</h2>
//                             <input type="search" 
//                              className="rounded px-2 w-[8rem] py-2 text-black h-8 text-[12px] outline-none" 
//                             />
//                         </button>
//                     </div>
//                     <div className='flex justify-between px-2 border-b-[1px] py-1'>
//                         <h2>SN</h2>
//                         <h2>Bus Name</h2>
//                         <h2>Bus No.</h2>
//                         <h2>Capacity</h2>
//                         <h2>IsActive</h2>
//                         <h2>Take action</h2>
//                     </div>
//                     <div className='flex flex-col'>
//                         <div className='flex justify-between px-2 border-b-[1px] py-2'>
//                             <h2>1</h2>
//                             <h2>Juneli 38</h2>
//                             <h2>Ba 1 kha 1815</h2>
//                             <h2>49</h2>
//                             <h2>Active</h2>
//                             <button className='text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-bodyTheme border-bodyTheme'>
//                                 <Link to={'./bus-view-info'}>View</Link>
//                                 <AiOutlineFolderView color='white' size={'20px'}/>
//                             </button>
                            
//                         </div>
                        
//                     </div>
//         </div>
        
        
//     </div>
//   )
// }

// export default BusList;