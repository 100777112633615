import React from 'react'
// import {LuArrowBigRightDash} from 'react-icons/lu'

const ticket = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div>
            <h2 className='font-bold'>Step 3 of 4</h2>
        </div>
        <div>
            <h2 className='px-[10px] text-[25px]  font-semibold'>Choose how to pay</h2>
        </div>  
        <div className='flex flex-col gap-4'>
            <div>
                    <button className=' text-center py-[.3rem] px-[5.5rem] bg-green-600 border-nav border-2'>eSewa</button>
            </div>
            <div>
                    <button className=' text-center py-[.3rem] px-[5.7rem] bg-gray-800 text-white border-white border-[1.5px]'>Khalti</button>
            </div>    
        </div>      
    </div>
  )
}

export default ticket