import React from 'react'
import {Link} from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiDeleteBin6Line } from 'react-icons/ri';

const routes = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div className='h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer'>
                    <div className='border-b-[1px] py-1 px-2'>
                    <button className='text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-green-500 border-green-500'>
                        <IoMdAdd color='white'/>
                        <Link to={'./add-new-routes'}>Add new routes</Link>
                    </button>
                    </div>
                    <div className='flex justify-between px-2 border-b-[1px] py-1'>
                        <h2 className='font-bold'>SN</h2>
                        <h2 className='font-bold'>Bus</h2>
                        <h2 className='font-bold'>Route</h2>
                        <h2 className='font-bold'>Status</h2>
                        <h2 className='font-bold'>Action</h2>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex justify-between px-2 border-b-[1px] py-1'>
                            <h2>1</h2>
                            <h2>Juneli</h2>
                            <h2>Kalanki - Gaushala</h2>
                            <Link className='underline'>Bus Details</Link>
                            <div className='flex flex-col gap-1'>
                                <button className='flex justify-center items-center gap-2 border-[1px] rounded-md px-2 bg-blue border-blue'>
                                    <AiOutlineEdit/>
                                    <h2>Edit</h2>
                                    </button>
                                <button className='flex justify-center items-center gap-2 border-[1px] rounded-md px-2 bg-red-600 border-bodyTheme'>
                                    <RiDeleteBin6Line/>
                                    <h2>Delete</h2>
                                    </button>
                            </div>
                        </div>
                        <div className='flex justify-between px-2 border-b-[1px] py-1'> 
                            <h2>2</h2>
                            <h2>Mahanagar</h2>
                            <h2>Koteshwor - Buspark</h2>
                            <Link className='underline'>Bus Details</Link>
                            <div className='flex flex-col gap-1'>
                                <button className='flex justify-center items-center gap-2 border-[1px] rounded-md px-2 bg-blue border-blue'>
                                    <AiOutlineEdit/>
                                    <h2>Edit</h2>
                                    </button>
                                <button className='flex justify-center items-center gap-2 border-[1px] rounded-md px-2 bg-red-600 border-bodyTheme'>
                                    <RiDeleteBin6Line/>
                                    <h2>Delete</h2>
                                    </button>
                            </div>
                        </div>
                    </div>
        </div>
        
        
    </div>
  )
}

export default routes