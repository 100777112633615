import React from 'react'

const routes = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div className='h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center flex-col gap-10'>
                    <div>
                        <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Add new routes!</h2>
                    </div>
                    <div className='flex gap-6'>
                        <input 
                        type="text" 
                        placeholder='Bus'
                        className="rounded px-2 py-3 h-8 text-[12px] outline-none"
                        />
                        <input 
                        type="text" 
                        placeholder='Route'
                        className="rounded px-2 py-3 h-8 text-[12px] outline-none"
                        />
                    </div>
                    <div>
                        <input 
                        type="text" 
                        placeholder='status'
                        className="rounded px-2 py-3 h-8 text-[12px] outline-none"
                        />
                    </div>
                    
                    <div>
                         <button className='rounded-md text-center px-[3.8rem] h-8 text-white  font-semibold bg-bodyTheme'>Add</button>
                    </div>
                    
        </div>
        
        
    </div>
  )
}

export default routes