
import "./App.css";
import IndexPage from "./pages/homePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RegisterPage from "./pages/RegisterPage";
import Footer from "./components/Footer";
import LoginPage from "./pages/LoginPage";
import AdminDashboard from "./pages/adminDashboard";
import BMCDashboard from './pages/bmcDashboard';
import CommuterDashboard from './pages/commuterDashboard';
import StaffDashboard from './pages/staffDashboard';
import Tickets from './pages/ticket'
import Tickets2 from './pages/ticket2'
import Tickets3 from './pages/ticket3'
import Tickets4 from './pages/ticket4'
import Tickets5 from './pages/ticket5'
import Tickets6 from './pages/ticket6'
import Route1 from './pages/routes'
import AddNewRoutes from './pages/addNewRoutes'
import ViewProfile from './pages/viewProfiles'
import Staff from './pages/staff'
import CommuterProfiles from "./pages/commuterProfile";
import AdminUsers from "./pages/adminUsers";
import AdminViewInfo from "./pages/adminViewInfo";
import AddBuses from "./pages/addBuses";
import BusList from "./pages/busList";
import BusViewInfo from './pages/busViewInfo'
import CommuterBusViewInfo from "./pages/commuterBusViewInfo";
import CommuterBusLists from "./pages/commuterBusLists";
import Api from './pages/api'
import CommuterProfilesEdit from "./pages/commuterProfilesEdit";
import CommuterProfilesEdit2 from "./pages/commuterProfilesEdit2";
function App() {
  return (
    <div className="bg-bodyTheme flex flex-col">
      <Router>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin-dashboard" element={<AdminDashboard/>}/>
          <Route path="/login/admin-dashboard" element={<AdminDashboard/>}/>
          <Route path="/login/admin-dashboard/routes" element={<AdminDashboard/>}/>
          <Route path="/bmc-dashboard" element={<BMCDashboard/>}/>
          <Route path="/commuter-dashboard" element={<CommuterDashboard/>}/>
          <Route path="/staff-dashboard" element={<StaffDashboard/>}/>
          <Route path="/tickets" element={<Tickets/>}/>
          <Route path="/tickets-2" element={<Tickets2/>}/>
          <Route path="/tickets-3" element={<Tickets3/>}/>
          <Route path="/tickets-4" element={<Tickets4/>}/>
          <Route path="/tickets-5" element={<Tickets5/>}/>
          <Route path="/tickets-6" element={<Tickets6/>}/>
          <Route path="/admin-dashboard/routes" element={<Route1/>}/>
          <Route path="/admin-dashboard/routes/add-new-routes" element={<AddNewRoutes/>}/>
          <Route path="/staff-dashboard/view-profiles" element={<ViewProfile/>}/>
          <Route path="/view-profiles" element={<ViewProfile/>}/>
          <Route path="commuter-profiles" element={<CommuterProfiles/>}/>
          <Route path="/commuter-dashboard/commuter-profiles" element={<CommuterProfiles/>}/>
          <Route path="/commuter-dashboard/commuter-profiles/commuter-profiles-edit" element={<CommuterProfilesEdit/>}/>
          <Route path="/commuter-profiles-edit" element={<CommuterProfilesEdit/>}/>
          <Route path="/bmc-dashboard/staffs" element={<Staff/>}/>
          <Route path="/staffs" element={<Staff/>}/>
          <Route path="/admin-users" element={<AdminUsers/>}/>
          <Route path="/admin-dashboard/admin-users" element={<AdminUsers/>}/>
          <Route path="/login/admin-dashboard/admin-users" element={<AdminUsers/>}/>
          <Route path="/admin-view-info" element={<AdminViewInfo/>}/>
          <Route path="/admin-dashboard/admin-users/admin-view-info" element={<AdminViewInfo/>}/>
          <Route path="/admin-dashboard/add-buses" element={<AddBuses/>}/>
          <Route path="/login/admin-dashboard/add-buses" element={<AddBuses/>}/>
          <Route path="/admin-dashboard/bus-lists" element={<BusList/>}/>
          <Route path="/bus-lists" element={<BusList/>}/>
          <Route path="/bus-view-info" element={<BusViewInfo/>}/>
          <Route path="/admin-dashboard/bus-lists/bus-view-info" element={<BusViewInfo/>}/>
          <Route path="/login/admin-dashboard/admin-users/admin-view-info" element={<AdminViewInfo/>}/>
          <Route path="/commuter-bus-view-info" element={<CommuterBusViewInfo/>}/>
          <Route path="/commuter-bus-lists" element={<CommuterBusLists/>}/>
          <Route path="/commuter-dashboard/commuter-bus-lists" element={<CommuterBusLists/>}/>
          <Route path="/commuter-dashboard/commuter-bus-lists/commuter-bus-view-info" element={<CommuterBusViewInfo/>}/>
          <Route path="/api" element={<Api/>}/>
          <Route path="/commuter-profiles-edit-2" element={<CommuterProfilesEdit2/>}/>
          <Route path="/admin-dashboard/admin-users/admin-view-info/:id" element={<AdminViewInfo/>} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
