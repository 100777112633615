import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineFolderView } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";

const AdminUsers = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Number of items to display per page

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:3031/commuter");
      const updatedData = response.data.map((user) => ({ ...user, role: "" }));
      setData(updatedData);
      console.log(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOptionChange = (event, userId) => {
    const selectedRole = event.target.value;
    setData((prevData) => {
      const updatedData = prevData.map((user) => {
        if (user.id === userId) {
          return { ...user, role: selectedRole };
        }
        return user;
      });
      return updatedData;
    });
  };

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4">
      <div className="h-auto min-h-[50vh] w-[70%] bg-gray-500 border-2 rounded-md text-white cursor-pointer">
        <div className="border-b-[1px] py-1 px-2 flex justify-between items-center">
          <h2>Users Info:</h2>
          <button className="text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-green-500 border-green-500">
            <Link to={"./add-new-routes"}>Add Users</Link>
            <IoMdAdd color="white" />
          </button>
        </div>
        <div className="flex justify-between px-2 border-b-[1px] py-1">
          <h2>SN</h2>
          <h2>Name</h2>
          <h2>Address</h2>
          <h2>Email</h2>
          <h2>Phone No.</h2>
          <h2>Role</h2>
          <h2>Take action</h2>
        </div>
        <div className="flex flex-col">
          {currentItems.map((user, index) => (
            <div
              key={user.id}
              className="flex justify-between px-2 border-b-[1px] py-2"
            >
              <h2>{user.id}</h2>
              <h2>{user.name}</h2>
              <h2>{user.address}</h2>
              <h2>{user.email}</h2>
              <h2>{user.phoneNumber}</h2>
              <div className="text-black">
                <select
                  value={user.role}
                  onChange={(event) => handleOptionChange(event, user.id)}
                  className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                >
                  <option value="">Select an option</option>
                  <option value="option1">Commuter</option>
                  <option value="option2">BMC</option>
                </select>
              </div>
              <button className="text-white gap-1 border-[1px] rounded-sm items-center flex text-center justify-center px-2 bg-bodyTheme border-bodyTheme">
                <Link to={`./admin-view-info/${user.id}`}>View</Link>
                <AiOutlineFolderView color="white" size={"20px"} />
              </button>
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center mt-4">
        {data.length > itemsPerPage && (
          <div className="flex gap-2 pb-2">
            {Array.from(
              { length: Math.ceil(data.length / itemsPerPage) },
              (_, index) => (
                <button
                  key={index}
                  onClick={() => paginate(index + 1)}
                  className={`px-3 py-1 rounded bg-bodyTheme ${
                    currentPage === index + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-400 text-blue-500"
                  }`}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        )}
      </div>
      </div>
      
    </div>
  );
};

export default AdminUsers;