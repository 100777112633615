import React from "react";
import {  Link } from "react-router-dom";
import Logo from '../images/logo.png'


const IndexNav = () => {
  return (
    <div className="h-auto w-full pt-1  bg-nav flex flex-col justify-between items-center ">
      <div className="flex justify-between w-full pb-1 px-[5rem]">
        <div className=" cursor-pointer flex gap-1 items-center border-[1px] border-white pl-[10px] pr-[10px] rounded">
          <span className="text-white">
            <img src={Logo} className="h-[30px] w-[50px]" alt="" />
          </span>
          <span className="text-white">SBF</span>
        </div>
        <div className="flex gap-2">
          <button className=" border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
            Ticket
          </button>
          <button className=" border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
            <Link to="/register">Register</Link>
          </button>
          <button className=" border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
            {/* <Link to="/login">Login</Link> */}
            <Link to="/login">Login</Link>
          </button>
          <div className=" cursor-pointer border-[1px] border-white text-white rounded p-[3px] text-sm flex justify-center items-center">
            Eng
          </div>
        </div>
      </div>
      <div className=" pt-1 pb-1 border-t-[1px] w-full flex justify-end gap-2 px-[5rem]">
        <button className="border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
          Plan Your Yourney
        </button>
        <button className="border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
          Services
        </button>
        <button className="border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
          Travel Information
        </button>
      </div>
    </div>
  );
};

export default IndexNav;
