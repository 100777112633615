import React from 'react'



const mainDashboard = () => {
  return (
    <div className='w-[80%] h-[92vh] bg-bodyTheme flex flex-col gap-4 items-center justify-center py-4'>
        <h2 className='h-[10%] text-3xl font-bold'>Welcome Harry!</h2>
        <div className='h-[80vh] w-full  flex flex-col justify-center items-center gap-4  mt-6'>
            <div>
                <h2 className='border-rounded border-black border-[2px] px-4'>Qucik Tips:</h2>
            </div>
            <div className='h-[70vh] w-[90%] bg-gray-300 border-2 flex items-center justify-center rounded-sm' >
                        Image Slider Here!
            </div>
        </div>
    </div>
  )
}

export default mainDashboard