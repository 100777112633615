import React from 'react'
import {Link} from 'react-router-dom'
import { BsPersonCircle , BsFillBusFrontFill , BsTicket} from 'react-icons/bs';
import {LuLayoutDashboard} from "react-icons/lu"
import {TbBrandBooking} from "react-icons/tb"
import {AiOutlineFolderView} from "react-icons/ai"
import {FaRoute } from "react-icons/fa"
import {VscFeedback} from "react-icons/vsc"
import {MdPayment} from "react-icons/md"


const sideBar = () => {
  return (
    <div className='h-[92vh] w-[20%] bg-bodyTheme py-5'>
        <div className='flex  gap-3 mx-3 items-center justify-center py-2 bg-gray-500'>
        <h2><BsPersonCircle size={'35px'} color='white'/></h2>
            <div className='text-white cursor-pointer'>
                <h2>Harry</h2>
                <h2>harry@gmail.com</h2>
                <Link to={'./commuter-profiles'}>View Profile</Link>
            </div>
        </div>
        <div className='text-white h-[69vh] pl-6 py-2 items-center justify-center flex-col flex mx-3 bg-gray-500 mt-6 gap-4'>
              <div className="flex gap-3  w-full cursor-pointer">
                  <h2><LuLayoutDashboard size={'30px'}/></h2>
                  <h2>Dashborad</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><TbBrandBooking size={'30px'}/></h2>
                  <h2>New Booking</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><AiOutlineFolderView size={'30px'} /></h2>
                  <h2>View Booking</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><FaRoute size={'30px'}/></h2>
                  <h2>Routes</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><BsFillBusFrontFill size={'30px'}/></h2>
                  <Link to={'./commuter-bus-lists'}>Buses</Link>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><BsTicket size={'30px'}/></h2>
                  <h2>Tickets</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><VscFeedback size={'30px'}/></h2>
                  <h2>Feedback</h2>
              </div>
              <div className="flex gap-3 w-full cursor-pointer">
                  <h2><MdPayment size={'30px'}/></h2>
                  <h2>Payment</h2>
              </div>
        </div>
    </div>
  )
}

export default sideBar