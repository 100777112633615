import React from 'react';
import { BsPersonCircle } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdNotificationsNone } from 'react-icons/md';
import SideBar from '../components/sideBar';
import MainDashboard from '../components/mainDashboard';

const AdminDashboard = () => {
  return (
    <div>
      <div className="flex h-[3rem] justify-between w-full pb-1 bg-nav items-center border-b-2 border-gray-500">
        <div className="ml-10 cursor-pointer flex gap-1 items-center border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
          <span>LOGO</span>
          <span>sbf</span>
        </div>
        <div className="flex gap-4 mr-10 pl-[10px] pr-[10px] items-center text-white">
          <h2>
            <MdNotificationsNone size={'20px'} color='white' />
          </h2>
          <div className='flex items-center gap-2 text-white cursor-pointer'>
            <h2><BsPersonCircle /></h2>
            <h2>admin</h2>
          </div>
          <div className='text-white'>
            <h2><BsThreeDotsVertical /></h2>
          </div>
        </div>
      </div>
      <div className='flex flex-col md:flex-row'>
        <SideBar />
        <MainDashboard />
      </div>
    </div>
  );
};

export default AdminDashboard;













// import React from 'react'
// import { BsPersonCircle } from 'react-icons/bs';
// import { BsThreeDotsVertical } from 'react-icons/bs';
// import { MdNotificationsNone } from 'react-icons/md';
// import SideBar from '../components/sideBar';
// import MainDashboard from '../components/mainDashboard';


// const adminDashboard = () => {
//   return (
//     <div>
//         <div className="flex h-[3rem] justify-between w-full pb-1 bg-nav items-center border-b-2 border-gray-500">
//         <div className=" ml-10 cursor-pointer flex gap-1 items-center border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
//           <span>LOGO</span>
//           <span>sbf</span>
//         </div>
//         <div className="flex gap-4 mr-10 pl-[10px] pr-[10px]  items-center text-white">
//           <h2><MdNotificationsNone size={'20px'} color='white'/></h2>
//           <div className='flex items-center gap-2 text-white cursor-pointer'>
//               <h2><BsPersonCircle/></h2>
//               <h2>admin</h2>
//           </div>
//           <div className='text-white'>
//             <h2><BsThreeDotsVertical/></h2>
//           </div>
//         </div>
//       </div>
//       <div className='flex'>
//         <SideBar/>
//         <MainDashboard/>
//       </div>
//     </div>
//   )
// }

// export default adminDashboard