import React, { useState } from 'react';
import { useNavigate , Link } from 'react-router-dom';
import axios from 'axios';

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      // Make API request to retrieve user data
      const response = await axios.get('http://localhost:3031/users');

      console.log(response.data)

    } catch (error) {
      setError('An error occurred. Please try again later.');
      console.error(error);
    }
  };

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div className="h-auto bg-gray-500 pt-2 pb-4 px-20 rounded w-[40%]">
        {error && <p className='text-red-500'>{error}</p>}
        <form onSubmit={handleFormSubmit} className="flex flex-col gap-2">
            <div className="flex flex-col">
              <p className="text-[15px] text-white">Login here!</p>
              <p className="text-[12px] text-white">
                Don't have an account?{" "}
                <Link className="text-blue-400 underline" to="/register">
                  click here
                </Link>
              </p>
            </div>
          <div className="flex flex-col gap-5 mt-2">
              <input
                    className="rounded px-2 py-3 h-6 text-[12px] outline-none"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
              <input
                    className="rounded px-2 py-3 h-6 text-[12px] outline-none"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
          </div>
          <div className="text-[12px] flex gap-2">
                <p className="text-white">Forgot password?</p>
                <p className="text-white">
                  <Link className="text-blue-400 underline" to="#">
                    Click here
                  </Link>{" "}
                  to reset.
                </p>
            </div>
          <button
                type="submit"
                className="bg-bodyTheme font-semibold rounded text-[15px] text-white py-1"
              >
                Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;

