import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    address: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    dob: "",
    user_type: "",
    gender: "",
    // agreeTerms: false,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your API endpoint URL
    // const apiUrl = "http://localhost:3031/users";
    const apiUrl = "http://127.0.0.1:8000/api/register";

    // Perform the API request
    axios
      .post(apiUrl, formData)
      .then((response) => {
        console.log("Success:", response.data);
        // Reset form fields
        setFormData({
          full_name: "",
          email: "",
          address: "",
          phone_number: "",
          password: "",
          password_confirmation: "",
          user_type: "",
          gender: "",
          dob: "",
          // agreeTerms: false,
        });
        setSuccessMessage("Registration successful! Please proceed to login.");
        setTimeout(() => {
          setSuccessMessage("");
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setErrorMessage("Registration failed. Please try again.");

        if (error.response) {
          // Error response received
          const errorData = error.response.data;
          console.log("Error Data:", errorData);
          if (errorData.errors) {
            const messages = Object.values(errorData.errors).flat();
            setErrorMessage(messages);
          } else {
            setErrorMessage(["Registration failed. Please try again."]);
          }
          // Set error message or perform other error handling
        } else {
          // Request failed
          console.error("Error:", error);
          setErrorMessage("Registration failed. Please try again.");
        }
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-bodyTheme">
      {errorMessage.length > 0 && (
        <div>
          {errorMessage.map((message, index) => (
            <div key={index}>Opps! {message}</div>
          ))}
        </div>
      )}

      {typeof errorMessage === "string" && (
        <div className="bg-red-200 text-white p-3 mb-4 rounded">
          Opps! {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="bg-green-200 text-green-800 p-3 mb-4 rounded">
          {successMessage}
        </div>
      )}
      <div className="bg-gray-300 p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Register</h2>
        {successMessage && (
          <div className="bg-green-200 text-green-800 p-3 mb-4 rounded">
            {successMessage}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block font-bold mb-1">
              Name:
            </label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              value={formData.full_name}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block font-bold mb-1">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address" className="block font-bold mb-1">
              Address:
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phoneNumber" className="block font-bold mb-1">
              Phone Number:
            </label>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block font-bold mb-1">
              Password:
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="block font-bold mb-1">
              Confirm Password:
            </label>
            <input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="dob" className="block font-bold mb-1">
              DOB
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="userType" className="block font-bold mb-1">
              User Type:
            </label>
            <select
              id="user_type"
              name="user_type"
              value={formData.user_type}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            >
              <option value="">Select User Type</option>
              <option value="bmc">bmc</option>
              <option value="staff">staff</option>
              <option value="commuter">Commuter</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="gender" className="block font-bold mb-1">
              Gender:
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded px-3 py-2"
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              {/* <option value="other">Other</option> */}
            </select>
          </div>
          <div className="mb-6">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="agreeTerms"
                name="agreeTerms"
                checked={formData.agreeTerms}
                // onChange={handleInputChange}
                className="mr-2"
              />
              <label htmlFor="agreeTerms" className="text-sm">
                By registering, you agree to the terms &amp; conditions
              </label>
            </div>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-300"
            >
              Register
            </button>
          </div>
        </form>
        <div className="text-center mt-4">
          <Link to="/login" className="text-blue-600 underline">
            Already have an account? Login here.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
