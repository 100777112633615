import React from 'react'
import {LuArrowBigRightDash} from 'react-icons/lu'

const ticket = () => {
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
        <div>
            <h2 className='font-bold'>Step 1 of 4</h2>
        </div>
        <div>
            <h2 className='px-[10px] text-[25px]'>Choose the card that is right for you!</h2>
        </div>
        <div className='flex flex-col gap-4 px-2'>
            <div className='flex gap-2 flex-col'>
                <div className='flex items-center  text-[20px] gap-2'>
                    <h2><LuArrowBigRightDash/></h2>
                    <h2>Select the bus of your choice</h2>
                </div>
                <div className='flex items-center  text-[20px] gap-2'>
                    <h2><LuArrowBigRightDash/></h2>
                    <h2>If you select individual buses , you shall receive Sole card</h2>
                </div>
                <div className='flex items-center  text-[20px] gap-2'>
                    <h2><LuArrowBigRightDash/></h2>
                    <h2>If you select all buses , you shall receive universal card</h2>
                </div>
            </div>
            <div>
                <h2 className='px-[2.2rem] text-center py-[.5rem] w-[38%] border-nav border-2'>Applicable Buses</h2>
            </div>
            <div className='text-[20px] flex flex-col gap-2'>
                <div className='flex gap-2'>
                    <input type="radio" />
                    <h2>Mahanagar Bus</h2>
                </div>
                <div className='flex gap-2'>
                    <input type="radio" />
                    <h2>Juneli Bus</h2>
                </div>
                <div className='flex gap-2'>
                    <input type="radio" />
                    <h2>Select all</h2>
                </div>
                <div>
                     <button className=' text-center px-[2rem] border-nav border-2'>Next</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ticket