import React from 'react'
import {LuUsers} from "react-icons/lu"
import {GrSchedules} from "react-icons/gr"
import {BsFillBusFrontFill} from "react-icons/bs"
import {MdPayment} from "react-icons/md"
import {FaRoute} from "react-icons/fa"
import {VscFeedback} from "react-icons/vsc"


const mainDashboard = () => {
  return (
    <div className='w-[80%] h-[92vh] bg-bodyTheme flex flex-col gap-4 items-center justify-center py-4'>
        <h2 className='h-[10%] text-3xl font-bold'>Welcome Administrator!</h2>
        <div className='h-[90%] w-full  flex flex-col justify-center items-center gap-8'>
            <div className='flex gap-60'>
                <div className='flex items-center flex-col gap-2'>
                    <h2><LuUsers size={'80px'}/></h2>
                    <h2 className='cursor-pointer  font-bold'>Users</h2>
                </div>
                <div className='flex items-center flex-col gap-2'>
                    <h2><GrSchedules size={'80px'}/></h2>
                    <h2 className='cursor-pointer font-bold'>Schedules</h2>
                </div>
            </div>
            <div className='flex gap-60'>
                <div className='flex items-center flex-col gap-2'>
                    <h2><BsFillBusFrontFill size={'80px'}/></h2>
                    <h2 className='cursor-pointer font-bold'>Buses</h2>
                </div>
                <div className='flex items-center flex-col gap-2'>
                    <h2><MdPayment size={'80px'}/></h2>
                    <h2 className='cursor-pointer font-bold'>Payments</h2>
                </div>
            </div>
            <div className='flex gap-60'>
                <div className='flex items-center flex-col gap-2'>
                    <h2><FaRoute size={'80px'} width={'90px'}/></h2>
                    <h2 className='cursor-pointer font-bold'>Routes</h2>
                </div>
                <div className='flex items-center flex-col gap-2'>
                    <h2><VscFeedback size={'80px'}/></h2>
                    <h2 className='cursor-pointer font-bold'>Feedback</h2>
                </div>
            </div>
        </div>
    </div>
  )
}

export default mainDashboard