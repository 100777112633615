import React, { useState } from 'react';
import { CiSaveUp2 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

const MyForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    dateOfBirth: '',
    gender: '',
    phoneNumber: '',
    profilePicture: null,
    documentProof: null,
    documentType: '',
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    if (e.target.name === 'profilePicture' || e.target.name === 'documentProof') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('http://localhost:3031/commuter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Error: ' + response.status);
      }
  
      const responseData = await response.json();
      console.log('Success:', responseData);
  
      // Reset form fields
      setFormData({
        name: '',
        email: '',
        address: '',
        dateOfBirth: '',
        gender: '',
        phoneNumber: '',
        profilePicture: null,
        documentProof: null,
        documentType: '',
      });

      //Save the data in local storage
      localStorage.setItem('postedData', JSON.stringify(responseData));
  
      // Redirect to display page with form data
      navigate('/commuter-dashboard/commuter-profiles', { state: formData  });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
      <form
        className='h-auto w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'
        onSubmit={handleSubmit}
      >
             <div className='w-[50%] gap-5 flex flex-col pl-[5rem]'>
                        <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Personal Details</h2>
                        </div>
                        <div className='flex flex-col gap-2'>

                              <input 
                                    type="text" 
                                    id="name" 
                                    name="name" 
                                    value={formData.name} 
                                    onChange={handleInputChange} 
                                    placeholder='Name'
                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                              />
                        
                              <input 
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email} onChange={handleInputChange} 
                                    placeholder='Email'
                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                              />
                              <input 
                                    type="text" 
                                    id="address" 
                                    name="address"
                                    value={formData.address}
                                    onChange={handleInputChange} 
                                    placeholder='Address'
                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                                     />
                              <input
                                    type="date"
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    value={formData.dateOfBirth}
                                    onChange={handleInputChange}
                                    placeholder='Date of Birth'
                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                              />
                              <select 
                              id="gender" 
                              name="gender" 
                              value={formData.gender} 
                              onChange={handleInputChange}
                              placeholder='Gender'
                              className="rounded px-1 w-[12rem] h-8 text-[12px] outline-none"
                              >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                              </select>
                              <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleInputChange}
                                placeholder='Phone Number'
                                className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                              />
                        </div>
                </div>
              

                <div className='w-[50%] flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5'>
                  <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Document Details</h2>
                  </div>
                  <div className='flex flex-col gap-2 mb-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                      <h2>Profile Picture:</h2>
                      <div>
                            <input type="file" id="profilePicture" name="profilePicture" onChange={handleInputChange} 
                                   className='bg-bodyTheme'
                            />

                      </div>
                  </div>
                  <div className='flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                        <h2>Identity Proof Document:</h2>
                        <div className='text-black'>
                            <select 
                              id="documentType" 
                              name="documentType" 
                              value={formData.documentType} 
                              onChange={handleInputChange}
                              className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
                              >
                              <option value="">Select Document Type</option>
                              <option value="citizenship">Citizenship</option>
                              <option value="passport">Passport</option>
                              <option value="nationalId">National ID</option>
                            </select>
                        </div>
                        <div>
                                <input 
                                    type="file" 
                                    id="documentProof" 
                                    name="documentProof" 
                                    onChange={handleInputChange} 
                                    className='bg-bodyTheme'
                                 />
                        </div>

                          <div>
                            <button className=' text-center px-[2rem] border-bodyTheme rounded-md text-white border-2 flex justify-center items-center gap-3 bg-bodyTheme'>
                                <div>
                                   Save
                                </div>
                                <CiSaveUp2/>
                                 </button>
                        </div>
                  </div>
                
                </div>
      </form>
    </div>
  );
};

export default MyForm;



























// import React, { useState } from 'react';
// import {CiSaveUp2} from 'react-icons/ci'


// const MyForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     address: '',
//     dateOfBirth: '',
//     gender: '',
//     phoneNumber: '',
//     profilePicture: null,
//     documentProof: null,
//     documentType: '',
//   });

//   const handleInputChange = (e) => {
//     if (e.target.name === 'profilePicture' || e.target.name === 'documentProof') {
//       setFormData({
//         ...formData,
//         [e.target.name]: e.target.files[0],
//       });
//     } else {
//       setFormData({
//         ...formData,
//         [e.target.name]: e.target.value,
//       });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await fetch('http://localhost:3031/commuter', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });

//       if (!response.ok) {
//         throw new Error('Error: ' + response.status);
//       }

//       const responseData = await response.json();
//       console.log('Success:', responseData);

//       // Reset form fields
//       setFormData({
//         name: '',
//         email: '',
//         address: '',
//         dateOfBirth: '',
//         gender: '',
//         phoneNumber: '',
//         profilePicture: null,
//         documentProof: null,
//         documentType: '',
//       });
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   return (
//     <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
              
//               <form 
//                 className='h-auto w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'
//                 onSubmit={handleSubmit}
//               >
//                 <div className='w-[50%] gap-5 flex flex-col pl-[5rem]'>
//                         <div>
//                             <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Personal Details</h2>
//                         </div>
//                         <div className='flex flex-col gap-2'>

//                               <input 
//                                     type="text" 
//                                     id="name" 
//                                     name="name" 
//                                     value={formData.name} 
//                                     onChange={handleInputChange} 
//                                     placeholder='Name'
//                                     className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                               />
                        
//                               <input 
//                                     type="email"
//                                     id="email"
//                                     name="email"
//                                     value={formData.email} onChange={handleInputChange} 
//                                     placeholder='Email'
//                                     className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                               />
//                               <input 
//                                     type="text" 
//                                     id="address" 
//                                     name="address"
//                                     value={formData.address}
//                                     onChange={handleInputChange} 
//                                     placeholder='Address'
//                                     className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                                      />
//                               <input
//                                     type="date"
//                                     id="dateOfBirth"
//                                     name="dateOfBirth"
//                                     value={formData.dateOfBirth}
//                                     onChange={handleInputChange}
//                                     placeholder='Date of Birth'
//                                     className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                               />
//                               <select 
//                               id="gender" 
//                               name="gender" 
//                               value={formData.gender} 
//                               onChange={handleInputChange}
//                               placeholder='Gender'
//                               className="rounded px-1 w-[12rem] h-8 text-[12px] outline-none"
//                               >
//                                     <option value="">Select Gender</option>
//                                     <option value="male">Male</option>
//                                     <option value="female">Female</option>
//                                     <option value="other">Other</option>
//                               </select>
//                               <input
//                                 type="tel"
//                                 id="phoneNumber"
//                                 name="phoneNumber"
//                                 value={formData.phoneNumber}
//                                 onChange={handleInputChange}
//                                 placeholder='Phone Number'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                               />
//                         </div>
//                 </div>
              

//                 <div className='w-[50%] flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5'>
//                   <div>
//                             <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Document Details</h2>
//                   </div>
//                   <div className='flex flex-col gap-2 mb-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
//                       <h2>Profile Picture:</h2>
//                       <div>
//                             <input type="file" id="profilePicture" name="profilePicture" onChange={handleInputChange} 
//                                    className='bg-bodyTheme'
//                             />

//                       </div>
//                   </div>
//                   <div className='flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
//                         <h2>Identity Proof Document:</h2>
//                         <div className='text-black'>
//                             <select 
//                               id="documentType" 
//                               name="documentType" 
//                               value={formData.documentType} 
//                               onChange={handleInputChange}
//                               className="rounded px-1 w-[5rem] h-8 text-[12px] outline-none"
//                               >
//                               <option value="">Select Document Type</option>
//                               <option value="citizenship">Citizenship</option>
//                               <option value="passport">Passport</option>
//                               <option value="nationalId">National ID</option>
//                             </select>
//                         </div>
//                         <div>
//                                 <input 
//                                     type="file" 
//                                     id="documentProof" 
//                                     name="documentProof" 
//                                     onChange={handleInputChange} 
//                                     className='bg-bodyTheme'
//                                  />
//                         </div>

//                           <div>
//                             <button className=' text-center px-[2rem] border-bodyTheme rounded-md text-white border-2 flex justify-center items-center gap-3 bg-bodyTheme'>
//                                 <div>
//                                    Save
//                                 </div>
//                                 <CiSaveUp2/>
//                                  </button>
//                         </div>
//                   </div>
                
//                 </div>
                

//               </form>
//     </div>

//   );
// };

// export default MyForm;
