import React , {useState} from 'react'
import {CiSaveUp2} from 'react-icons/ci'

const ViewProfiles = () => {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
            <div className='h-auto w-[40%] py-3 bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center flex-col gap-2'>
                    <div>
                        <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Personal Details</h2>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <input 
                               type="text" 
                               placeholder='Name'
                               className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                        />
                        <input 
                               type="text" 
                               placeholder='Address'
                               className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                        />
                        <input 
                               type="text" 
                               placeholder='Phone number'
                               className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                        />
                        <input 
                               type="text" 
                               placeholder='License number'
                               className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                        />
                    </div>
                    <div>
                        <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Document Details</h2>
                    </div>
                    <div className='flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                        <h2>License Photo:</h2>
                        <div>
                            <input className='bg-bodyTheme' type="file" onChange={handleFileChange} />
                            {/* {selectedFile && <p>Selected file: {selectedFile.name}</p>} */}
                        </div>

                    </div>
                    <div>
                        <button className=' text-center px-[2rem] border-bodyTheme rounded-md text-white border-2 flex justify-center items-center gap-3 bg-bodyTheme'>
                            <div>
                               Save
                            </div>
                            <CiSaveUp2/>
                             </button>
                    </div>
                    
                    
            </div>
    </div>
  )
}

export default ViewProfiles;


