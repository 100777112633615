import React from "react";

const Footer = () => {
  return (
    <div>
      {/* <div className="h-[2rem] bg-red-400 mt-8 flex items-center px-[5rem] text-[10px]"> */}
      <div className="h-[2rem] bg-nav text-white flex  items-center px-[5rem] text-[10px]">

        © SBF 2023. All right reserved.
      </div>
    </div>
  );
};

export default Footer;
