import React, { useEffect, useState } from "react";
import { CiSaveUp2 } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { TiTickOutline } from "react-icons/ti";
import { RxUpdate } from "react-icons/rx";
import axios from "axios";
import { useParams } from "react-router-dom";

const AdminViewInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3031/commuter/${id}`
        );
        setUserInfo(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserInfo();
  }, [id]);

  const handleUpdate = async () => {
    try {
      await axios.put(`http://localhost:3031/commuter/${id}`, userInfo);
      setMessage("Data updated successfully!");
      setIsSuccess(true);
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    } catch (error) {
      console.error("Error:", error);
      setMessage("opps! , Failed to update data!");
      setIsSuccess(false);
      setTimeout(() => {
        setMessage(null);
      }, 2000);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [name]: value,
    }));
  };

  if (!userInfo) {
    return <div className="h-full w-full bg-bodyTheme">Loading...</div>;
  }

  return (
    <div className="h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4">
      {message && (
        <div
          className={`text-white mt-3 p-2 rounded-md ${
            isSuccess ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {message}
        </div>
      )}
      <div className="h-auto w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2">
        <div className="w-[50%] gap-5 flex flex-col pl-[5rem] ">
          <div>
            <h2 className="px-[10px] text-[25px]  font-semibold text-white">
              Personal Details
            </h2>
          </div>
          <div className="flex flex-col gap-2">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={userInfo.name}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={userInfo.email}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={userInfo.address}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
            <input
              type="text"
              name="dateOfBirth"
              placeholder="Date of Birth"
              value={userInfo.dateOfBirth}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
            <input
              type="text"
              name="gender"
              placeholder="Gender"
              value={userInfo.gender}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
            <input
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              value={userInfo.phoneNumber}
              onChange={handleChange}
              className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
            />
          </div>
        </div>
        <div className="w-[50%] flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5">
          <div>
            <h2 className="px-[10px] text-[25px]  font-semibold text-white">
              Document Details
            </h2>
          </div>
          <div className="flex flex-col gap-2 mb-2 text-white border-[1px] rounded-md border-white px-5 py-2">
            <h2 className="h-[80px] w-[150px] flex justify-center items-center">
              Profile Picture Here
            </h2>
          </div>
          <div className="flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2">
            <h2 className="h-[80px] w-[150px] flex justify-center items-center">
              Identity Proof Document:
            </h2>
          </div>
          <div className="flex gap-3">
            <button className=" text-center px-[1rem] border-green-500 rounded-md text-white border-2 flex justify-center items-center gap-3 bg-green-500">
              <div>Accept</div>
              <TiTickOutline />
            </button>
            <button className=" text-center px-[1rem] border-red-500 rounded-md text-white border-2 flex justify-center items-center gap-3 bg-red-500">
              <div>Reject</div>
              <RxCross2 />
            </button>
            <button
              onClick={handleUpdate}
              className=" text-center px-[1rem] border-bodyTheme rounded-md text-white border-2 flex justify-center items-center gap-3 bg-bodyTheme"
            >
              <div>Update</div>
              <RxUpdate color="white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminViewInfo;
