import React , {useState} from 'react'
import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const CommuterProfiles = () => {

  // const location = useLocation();
  // const formData = location.state;

  const storedData = localStorage.getItem('postedData');
  const formData = JSON.parse(storedData);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    };
    const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
    };

  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
            {formData ? <div className='h-auto w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'>
                    <div className='w-[50%] gap-5 flex flex-col pl-[5rem] '>
                        <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Personal Details</h2>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <input
                                   type="text"
                                   readOnly
                                   value={formData.name}
                                   placeholder='Name'
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="email"
                                   placeholder='Email'
                                    readOnly
                                   value={formData.email}
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Address'
                                    readOnly
                                   value={formData.address}
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Date of Birth'
                                    readOnly
                                   value={formData.dateOfBirth}
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Gender'
                                    readOnly
                                   value={formData.gender}
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="number"
                                   placeholder='Phone Number'
                                    readOnly
                                   value={formData.phoneNumber}
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                        </div>
                    </div>
                    <div className='w-[50%] flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5'>
                    <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Document Details</h2>
                        </div>
                        <div className='flex flex-col gap-2 mb-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                            <h2 className='h-[80px] w-[150px] flex justify-center items-center'>
                              Profile Picture Here: 
                            </h2>
                        </div>
                        <div className='flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                            <h2 className='h-[80px] w-[150px] flex justify-center items-center'>
                              Identity Proof Document: 
                            </h2>
                            
                        </div>
                        <Link to={'./commuter-profiles-edit'}>
                            <button className=' text-center px-[2rem] rounded-md text-white border-2 flex justify-center items-center gap-3 bg-blue border-blue'>
                                <div >
                                   Edit
                                </div>
                                <AiOutlineEdit/>
                                 </button>
                        </Link>
                    </div>
                    
                    
            </div> : <div className='h-auto w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'>
                    <div className='w-[50%] gap-5 flex flex-col pl-[5rem] '>
                        <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Personal Details</h2>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <input
                                   type="text"
                                   readOnly
                                   placeholder='Name'
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="email"
                                   placeholder='Email'
                                    readOnly
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Address'
                                    readOnly
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Date of Birth'
                                    readOnly
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="text"
                                   placeholder='Gender'
                                    readOnly
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                            <input
                                   type="number"
                                   placeholder='Phone Number'
                                    readOnly
                                   className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
                            />
                        </div>
                    </div>
                    <div className='w-[50%] flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5'>
                    <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Document Details</h2>
                        </div>
                        <div className='flex flex-col gap-2 mb-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                            <h2 className='h-[80px] w-[150px] flex justify-center items-center'>
                              Profile Picture Here: 
                            </h2>
                        </div>
                        <div className='flex flex-col gap-2 text-white border-[1px] rounded-md border-white px-5 py-2'>
                            <h2 className='h-[80px] w-[150px] flex justify-center items-center'>
                              Identity Proof Document: 
                            </h2>
                            
                        </div>
                        <Link to={'./commuter-profiles-edit'}>
                            <button className=' text-center px-[2rem] rounded-md text-white border-2 flex justify-center items-center gap-3 bg-blue border-blue'>
                                <div >
                                   Edit
                                </div>
                                <AiOutlineEdit/>
                                 </button>
                        </Link>
                    </div>
                    </div>}
                    
    </div>
  )
}

export default CommuterProfiles;


