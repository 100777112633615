import React from "react";
import Footer from "../components/Footer";
import IndexNav from "../components/IndexNav";
import {BiSearch} from 'react-icons/bi'
import HomePage1 from '../images/home.png'

const HomePage = () => {
  return (
    <div className="bg-bodyTheme h-[100vh] w-full">
      <IndexNav />
      {/* <div className="h-[50vh] m-auto flex justify-between pl-[5rem] pr-[5rem]">
        <div>map</div>
        <div>mobile</div>
        <div>Bus</div>
      </div> */}
      <div className="h-[60vh] flex justify-between pl-[5rem] pr-[5rem]  items-center">
        <img src={HomePage1} className="pl-[17rem]" alt="" />
        
      </div>
      <div className="h-[10vh] bg-gray-500 w-[75%] items-center justify-center flex  m-auto mt-4 gap-2">
        <div className="border-[1px] border-orange-300 h-[2rem] flex justify-center items-center bg-gray-300">
          From:
          <input
            type="text"
            placeholder="____________"
            className="outline-none  border-blue-200 w-[70%] rounded bg-transparent"
          />
        </div>
        <div className="border-[1px] border-orange-300 h-[2rem] flex justify-center items-center bg-gray-300">
          To:
          <input
            type="text"
            placeholder="____________"
            className="outline-none  border-blue-200 w-[70%] rounded bg-transparent"
          />
        </div>
        <div className="border-[1px] border-orange-300 h-[2rem] flex justify-center items-center bg-gray-300">
          Date:
          <input
            type="text"
            placeholder="____________"
            className="outline-none  border-blue-200 w-[70%] rounded bg-transparent"
          />
        </div>
        <div className="border-[1px] border-orange-300 h-[2rem] flex justify-center items-center bg-bodyTheme text-white">
          <button className="flex justify-center items-center gap-2 px-4 bg-bodyTheme">
                  <BiSearch/>
                  <h2>Search</h2>
          </button>
        </div>
      </div>
      <div className="m-auto w-[75%] h-[10vh] flex justify-center items-center text-xl">
        Make Your Trip Easy and Comfortable
      </div>
    </div>
  );
};

export default HomePage;
