import React, { useState } from 'react';
import { IoMdAdd } from 'react-icons/io';

const AddBuses = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [busInfo, setBusInfo] = useState('');
  const [busNo, setBusNo] = useState('');
  const [createdDate, setCreatedDate] = useState('');
  const [busName, setBusName] = useState('');
  const [email, setEmail] = useState('');
  const [capacity, setCapacity] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [driverId, setDriverId] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [apiUsername, setApiUsername] = useState('');
  const [apiPassword, setApiPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a payload object with the form data
    const payload = {
      selectedFile,
      selectedOption,
      busInfo,
      createdDate,
      busName,
      busNo,
      email,
      capacity,
      registrationNumber,
      driverId,
      apiUrl,
      apiUsername,
      apiPassword,
    };

    try {
      // Make the API post request
      const response = await fetch('http://localhost:3031/bus_lists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Check if the request was successful
      if (response.ok) {
        setMessage('Data submitted successfully!');
        setIsSuccess(true);
        // Reset form fields
        setSelectedFile(null);
        setSelectedOption('');
        setBusInfo('');
        setBusNo('');
        setCreatedDate('');
        setBusName('');
        setEmail('');
        setCapacity('');
        setRegistrationNumber('');
        setDriverId('');
        setApiUrl('');
        setApiUsername('');
        setApiPassword('');
      } else {
        setMessage('Failed to submit data. Please try again.');
        setIsSuccess(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while submitting the data.');
      setIsSuccess(false);
    }
    // Clear the message after 1 second
    setTimeout(() => {
      setMessage('');
      setIsSuccess(false);
    }, 2000);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setCreatedDate(selectedDate);
  };

  return (
    <div className="h-screen w-full bg-bodyTheme flex flex-col items-center justify-center gap-4">
      {message && (
        <div
          className={`mt-4 p-2 text-white ${
            isSuccess ? 'bg-green-500' : 'bg-red-500'
          }`}
        >
          {message}
        </div>
      )}
      <form
        className="h-[70vh] w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%] bg-gray-500 border-2 rounded-md cursor-pointer px-[3rem] flex flex-col gap-2"
        onSubmit={handleSubmit}
      >
        <div className="w-full gap-5 flex flex-col sm:flex-row">
          <div className="w-full sm:w-[50%] flex flex-col gap-2">
            <div>
              <h2 className="px-[10px] text-[25px] font-semibold text-white">
                Add Buses
              </h2>
            </div>
            <input
              type="text"
              placeholder="Bus Name"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={busName}
              onChange={(e) => setBusName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="number"
              placeholder="Capacity"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <input
              type="number"
              placeholder="Registration Number"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
            />
            <input
              type="number"
              placeholder="Driver Id"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={driverId}
              onChange={(e) => setDriverId(e.target.value)}
            />
            <div>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleOptionChange}
                className="rounded px-2 h-8 text-[12px] outline-none"
              >
                <option value="">Is active?</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <p className="text-white">Selected option: {selectedOption}</p>
            </div>
          </div>
          <div className="w-full sm:w-[50%] flex flex-col gap-3 border-t sm:border-t-0 sm:border-l-0  sm:pl-10 py-5">
            <div>
              <h2 className="px-[10px] text-[25px] font-semibold text-white">
                Other Details:
              </h2>
            </div>
            <input
              type="text"
              placeholder="API URL"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={apiUrl}
              onChange={(e) => setApiUrl(e.target.value)}
            />
            <input
              type="text"
              placeholder="API Username"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={apiUsername}
              onChange={(e) => setApiUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="API Password"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={apiPassword}
              onChange={(e) => setApiPassword(e.target.value)}
            />
            <input
              type="text"
              placeholder="Bus Info"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={busInfo}
              onChange={(e) => setBusInfo(e.target.value)}
            />
            <input
              type="number"
              placeholder="Bus No"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              value={busNo}
              onChange={(e) => setBusNo(e.target.value)}
            />
            <input
              type="date"
              placeholder="Created Date"
              className="rounded px-2 py-3 h-8 text-[12px] outline-none"
              min={new Date().toISOString().split('T')[0]}
              onChange={handleDateChange}
            />
            <div className="pt-4">
              <button
                type="submit"
                className="text-white gap-1 border-[1px] w-[12rem] h-8 rounded-sm items-center flex text-center justify-center px-2 bg-green-500 border-green-500"
              >
                <h2>Add Bus</h2>
                <IoMdAdd color="white" />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddBuses;




















// import React , {useState} from 'react'
// import {IoMdAdd} from 'react-icons/io'
// import { Link } from 'react-router-dom'

// const AddBuses = () => {

//   const [selectedFile, setSelectedFile] = useState(null);

//   const handleFileChange = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };

//     const [selectedOption, setSelectedOption] = useState('');
//     const [selectedOption2, setSelectedOption2] = useState('');

//     const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//     };
//     const handleOptionChange2 = (event) => {
//     setSelectedOption2(event.target.value);
//     };

//   return (
//     <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
//             <form className='h-[70vh] w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'>
//                     <div className='w-[50%] gap-5 flex flex-col pl-[5rem] '>
//                         <div>
//                             <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Add Buses</h2>
//                         </div>
//                         <div className='flex flex-col gap-2'>
//                             <input
//                                    type="text"
//                                    placeholder='Bus Name'
//                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                             />
//                             <input
//                                    type="email"
//                                    placeholder='Bus Number'
//                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                             />
//                             <input
//                                    type="number"
//                                    placeholder='Capacity'
//                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                             />
                            
//                             <input
//                                    type="number"
//                                    placeholder='Registration Number'
//                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                             />
//                             <input
//                                    type="number"
//                                    placeholder='Driver Id'
//                                    className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                             />
//                             <div>
//                                 <select 
//                                   id="dropdown" 
//                                   value={selectedOption} 
//                                   onChange={handleOptionChange}
//                                   className="rounded px-2 w-[12rem]  h-8 text-[12px] outline-none"

//                                 >
//                                   <option value="">Is active?</option>
//                                   <option value="Yes">Yes</option>
//                                   <option value="No">No</option>
//                                 </select>
//                                 <p className='text-white'>Selected option: {selectedOption}</p>
//                               </div>
//                         </div>
//                     </div>
//                     <div className='w-[50%] h-full flex flex-col gap-3 border-l-[1px] justify-center items-center pl-10 py-5'>
//                         <div>
//                             <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Other Details:</h2>
//                         </div>
                        
//                         <input
//                                 type="text"
//                                 placeholder='API URL'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                         />
//                         <input
//                                 type="text"
//                                 placeholder='API Username'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                         />
//                         <input
//                                 type="password"
//                                 placeholder='API Password'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                         />
//                         <input
//                                 type="text"
//                                 placeholder='Bus Info'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                         />
//                         <input
//                                 type="date"
//                                 placeholder='Created Date'
//                                 className="rounded px-2 w-[12rem] py-3 h-8 text-[12px] outline-none"
//                         />
//                         <div className='pt-4'>
//                                 <button className='text-white gap-1 border-[1px] w-[12rem] h-8 rounded-sm items-center flex text-center justify-center px-2 bg-green-500 border-green-500'>
//                                     <Link to={'./add-new-routes'}>Add Bus</Link>
//                                     <IoMdAdd color='white'/>
//                                 </button>     
//                         </div>
//                     </div>
                    
                    
//             </form>
//     </div>
//   )
// }

// export default AddBuses;


