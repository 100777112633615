import React , {useState} from 'react'
import {IoMdAdd} from 'react-icons/io'
import {RxUpdate} from 'react-icons/rx'
import { Link } from 'react-router-dom'

const CommuterBusViewInfo = () => {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

    const [selectedOption, setSelectedOption] = useState('');
    const [selectedOption2, setSelectedOption2] = useState('');

    const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    };
    const handleOptionChange2 = (event) => {
    setSelectedOption2(event.target.value);
    };

  return (
    <div className='h-[100vh] w-full bg-bodyTheme flex flex-col items-center justify-center gap-4'>
            <div className='h-[70vh] w-[70%] bg-gray-500 border-2 rounded-md  cursor-pointer px-[3rem] flex items-center justify-center  gap-2'>
                    <div className='w-[50%] gap-5 flex flex-col pl-[5rem] '>
                        <button className='text-white gap-1 border-[1px] w-[12rem] h-8 rounded-sm items-center flex text-center justify-center px-2 bg-bodyTheme border-bodyTheme'>
                                    <Link to={'./add-new-routes'}>Basic Info</Link>
                                    {/* <IoMdAdd color='white'/> */}
                                </button>  
                        <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Bookings</h2>
                        </div>
                        <div>
                            <h2 className='px-[10px] text-[25px]  font-semibold text-white'>Staffs</h2>
                        </div>
                        
                    </div>
                    <div className='w-[50%] h-full flex flex-col gap-3 border-l-[1px] items-center pl-10 py-5'>
                        {/* <div>
                            <h2 className='text-[25px] pl-0  font-semibold text-white'>Basic Info:</h2>
                        </div> */}
                        
                        <div className='flex flex-col gap-2 text-white'>
                            <h2 className='text-[25px] pl-0  font-semibold text-white'>Basic Info:</h2>
                            <h2>Bus Name : Mahanagar</h2>
                            <h2>Bus Number:</h2>
                            <h2>Capicity:</h2>
                            <h2>Registration Number:</h2>
                            <h2>GPS API Username:</h2>
                            <h2>GPS API Password:</h2>
                            <h2>Created Date:</h2>
                            <h2>Modified Date:</h2>
                        </div>
                    </div>
                    
                    
            </div>
    </div>
  )
}

export default CommuterBusViewInfo;


