import React, { useState } from "react";
import {NavLink} from "react-router-dom"

import {
  BsPersonCircle,
  BsFillBusFrontFill,
  BsDatabaseLock,
} from "react-icons/bs";
import { LuLayoutDashboard } from "react-icons/lu";
import { LuUsers } from "react-icons/lu";
import { AiOutlineSchedule } from "react-icons/ai";
import { FaRoute } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { VscFeedback } from "react-icons/vsc";
import { MdPayment } from "react-icons/md";
import { Link } from "react-router-dom";

const SideBar = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [showOptions2, setShowOptions2] = useState(false);
  const handleBusesClick = () => {
    setShowOptions(!showOptions);
  };
  const handleUserClick = () => {
    setShowOptions2(!showOptions2);
  };

  return (
    <div className="h-screen w-[20%] bg-bodyTheme py-5">
      <div className="flex  gap-3 mx-3 items-center justify-center py-2 bg-gray-500">
        <h2>
          <BsPersonCircle size={"35px"} color="white" />
        </h2>
        <div className="text-white cursor-pointer">
          <h2>admin</h2>
          <h2>admin@gmail.com</h2>
          <h2>View Profile</h2>
        </div>
      </div>
      <div className="text-white h-auto pl-6 py-2 items-center justify-center flex-col flex mx-3 bg-gray-500 mt-6 gap-4">
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <LuLayoutDashboard size={"30px"} />
          </h2>
          <h2>Dashboard</h2>
        </div>
        <div className="flex flex-col w-full justify-center cursor-pointer ">
          <div onClick={handleUserClick} className="flex  gap-3">
            <h2>
              <LuUsers size={"30px"} />
            </h2>
            <h2>Users</h2>
          </div>
          <div>
            {showOptions2 && (
              <div className=" bg-gray-400 w-auto px-3 py-2 flex flex-col">
                <Link to={"/admin-dashboard/admin-users"}>Verified User</Link>
                <Link to={"#"}>Unverified User</Link>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-3 w-full cursor-pointer ">
          <h2>
            <AiOutlineSchedule size={"30px"} />
          </h2>
          <h2>Schedules</h2>
        </div>
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <FaRoute size={"30px"} />
          </h2>
          <Link to={"./routes"}>Routes</Link>
        </div>
        <div className="flex flex-col  w-full cursor-pointer">
          <div onClick={handleBusesClick} className="flex gap-3">
            <h2>
              <BsFillBusFrontFill size={"30px"} />
            </h2>
            <h2>Buses</h2>
          </div>
          <div>
              {showOptions && (
                <div className="mt-2 bg-gray-400 px-3 py-1 flex flex-col">
                  <Link to={"./bus-lists"}>Bus List</Link>
                  <Link to={"./add-buses"}>Add Buses</Link>
                </div>
              )}
          </div>
        </div>
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <TbReportAnalytics size={"30px"} />
          </h2>
          <h2>Reports</h2>
        </div>
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <VscFeedback size={"30px"} />
          </h2>
          <h2>Feedback</h2>
        </div>
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <BsDatabaseLock size={"30px"} />
          </h2>
          <h2>Database</h2>
        </div>
        <div className="flex gap-3 w-full cursor-pointer">
          <h2>
            <MdPayment size={"30px"} />
          </h2>
          <h2>Payment</h2>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
