import React from 'react'
import { BsPersonCircle } from 'react-icons/bs';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdNotificationsNone } from 'react-icons/md';
import StaffSideBar from '../components/StaffSideBar'
import StaffMainDashboard from '../components/StaffMainDashboard'


const adminDashboard = () => {
  return (
    <div>
        <div className="flex h-[3rem] justify-between w-full pb-1 bg-nav items-center border-b-2 border-gray-500">
        <div className=" ml-10 cursor-pointer flex gap-1 items-center border-[1px] border-white text-white pl-[10px] pr-[10px] rounded">
          <span>LOGO</span>
          <span>sbf</span>
        </div>
        <div className="flex gap-4 mr-10 pl-[10px] pr-[10px]  items-center text-white">
          <h2><MdNotificationsNone size={'20px'} color='white'/></h2>
          <div className='flex items-center gap-2 text-white cursor-pointer'>
              <h2><BsPersonCircle/></h2>
              <h2>John</h2>
          </div>
          <div className='text-white'>
            <h2><BsThreeDotsVertical/></h2>
          </div>
        </div>
      </div>
      <div className='flex'>
        <StaffSideBar/>
        <StaffMainDashboard/>
      </div>
    </div>
  )
}

export default adminDashboard